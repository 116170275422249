import { render, staticRenderFns } from "./TableDetails.vue?vue&type=template&id=d217c6f2"
import script from "./TableDetails.vue?vue&type=script&lang=js"
export * from "./TableDetails.vue?vue&type=script&lang=js"
import style0 from "./TableDetails.vue?vue&type=style&index=0&id=d217c6f2&prod&lang=less&scope=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "TableDetails.vue"
export default component.exports